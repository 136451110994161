<template>
  <div class="work-order-form">
    <van-form
      ref="work_up_form"
      class="work-up-form"
      input-align="right"
      error-message-align="right"
      validate-trigger="onSubmit"
      :readonly="mode === 'view'"
      :colon="true"
      :scroll-to-error="true"
      :show-error="false"
      :show-error-message="true"
      :submit-on-enter="false"
      @submit="submitOnHandle"
    >
      <van-field
        readonly
        required
        v-model="textForm.orderTypeText"
        label="事件类型"
        placeholder="请选择事件类型"
        :is-link="mode === 'edit'"
        arrow-direction="down"
        :rules="[{ required: true, message: '事件类型不能为空' }]"
        @click="mode === 'edit' ? (orderTypePickShow = true) : ''"
      />
      <van-field
        required
        v-model="form.orderAddress"
        label="事件地点"
        placeholder="请输入事件地点"
        :rules="[{ required: true, message: '事件地点不能为空' }]"
        @click-right-icon="mapPointOnClick"
      >
        <template v-if="mode === 'edit'" #right-icon>
          <img src="./img/map_point.png" height="24px" />
        </template>
      </van-field>
      <van-field
        required
        v-model="form.orderTitle"
        label="事件标题"
        placeholder="请输入事件标题"
        :rules="[{ required: true, message: '事件标题不能为空' }]"
      />
      <van-field
        class="work-describe"
        required
        v-model="form.orderDisc"
        type="textarea"
        label="事件描述"
        placeholder="请输入事件描述"
        input-align="left"
        error-message-align="left"
        rows="2"
        autosize
        maxlength="200"
        show-word-limit
        :rules="[{ required: true, message: '事件描述不能为空' }]"
      />
      <van-field
        class="work-photo"
        required
        label="事件照片"
        input-align="left"
        error-message-align="left"
        :rules="[{ required: true, message: '事件照片不能为空' }]"
      >
        <template #input>
          <v-upload
            ref=""
            class=""
            :imgUrl.sync="form.photos"
            :activityPicture.sync="form.imageUrl"
            :maxCount="10"
            :readonly="mode === 'view'"
            :show-upload="mode === 'edit'"
            :deletable="mode === 'edit'"
            uploadUrl="/cyproxy/sys/uploadFile"
          />
        </template>
      </van-field>
      <van-field
        readonly
        required
        v-model="textForm.areaText"
        label="所属片区"
        placeholder="请选择所属片区"
        :is-link="mode === 'edit'"
        arrow-direction="down"
        :rules="[{ required: true, message: '所属片区不能为空' }]"
        @click="mode === 'edit' ? (areaPickShow = true) : ''"
      />
      <van-field
        readonly
        required
        v-model="textForm.assignUnitText"
        label="交办单位"
        placeholder="请选择交办单位"
        :is-link="mode === 'edit'"
        arrow-direction="down"
        :rules="[{ required: true, message: '交办单位不能为空' }]"
        @click="mode === 'edit' ? (assignUnitPickShow = true) : ''"
      />
      <van-field
        readonly
        v-model="textForm.superviseUnitText"
        label="监督单位"
        placeholder="请选择监督单位"
        :is-link="mode === 'edit'"
        arrow-direction="down"
        @click="mode === 'edit' ? (superviseUnitPickShow = true) : ''"
      />
    </van-form>

    <byMapGetInfo :popupShow.sync="orderAddressShow" @ok="mapSelectOnOk" />
    <v-picker
      :columns="orderTypeTreeOptions"
      :valueShow="orderTypePickShow"
      valueKey="text"
      :defaultIndex="0"
      @clickOverlay="orderTypePickShow = false"
      @cancel="orderTypePickShow = false"
      @confirmPicker="orderTypePickOnOk"
    />
    <v-picker
      :columns="areaOptions"
      :valueShow="areaPickShow"
      valueKey="text"
      :defaultIndex="0"
      @clickOverlay="areaPickShow = false"
      @cancel="areaPickShow = false"
      @confirmPicker="areaPickOnOk"
    />
    <v-picker
      :columns="assignUnitTreeOptions"
      :valueShow="assignUnitPickShow"
      valueKey="text"
      :defaultIndex="0"
      @clickOverlay="assignUnitPickShow = false"
      @cancel="assignUnitPickShow = false"
      @confirmPicker="assignUnitPickOnOk"
    />
    <v-picker
      :columns="superviseUnitOptions"
      :valueShow="superviseUnitPickShow"
      valueKey="text"
      :defaultIndex="0"
      @clickOverlay="superviseUnitPickShow = false"
      @cancel="superviseUnitPickShow = false"
      @confirmPicker="superviseUnitPickOnOk"
    />
  </div>
</template>

<script>
import { getDictOpsUrl, getOrderWorkDetailUrl, getSjlxListUrl, getPqSqDwListUrl } from './api.js';
import { mapState } from 'vuex';
import { Button, Form } from 'vant';
import byMapGetInfo from '@/components/bussiness/byMapGetInfo.vue';
import _ from 'lodash';

export default {
  name: 'workOrderForm',
  components: {
    [Button.name]: Button,
    [Form.name]: Form,
    byMapGetInfo
  },
  props: {
    mode: {
      type: String,
      default: 'edit' // 可编辑: edit    仅查看: view
    },
    existForm: Function,
    orderId: [String, Number]
  },
  model: {},
  data() {
    return {
      orderTypeOptions: [],
      orderTypeTreeOptions: [],
      orderTypeMap: {},
      areaOptions: [],
      areaMap: {},
      assignUnitTreeOptions: [],
      assignUnitOptions: [],
      assignUnitMap: {},
      superviseUnitOptions: [],
      superviseUnitMap: {},
      areaCommunityAssignOptions: [],
      areaCommunityAssignTreeOptions: [],
      areaCommunityAssignMap: {},
      textForm: {
        orderTypeText: '',
        areaText: '',
        assignUnitText: '',
        superviseUnitText: ''
      },
      form: {
        userId: '',
        orderType: '',
        orderAddress: '',
        orderTitle: '',
        orderDisc: '',
        imageUrl: '',
        photos: '',
        area: '',
        assignCommunity: '',
        assignUnit: '',
        superviseUnit: ''
      },
      orderAddressShow: false,
      orderTypePickShow: false,
      areaPickShow: false,
      assignUnitPickShow: false,
      superviseUnitPickShow: false
    };
  },
  computed: {
    ...mapState(['userId', 'tenantId', 'communityId', 'houseId'])
  },
  watch: {},
  created() {
    // , getSjlxListUrl, getPqSqDwListUrl
    Promise.all([
      this.$axios.get(getDictOpsUrl, { params: { dictType: '4' } }),
      this.$axios.get(getSjlxListUrl),
      this.$axios.get(getPqSqDwListUrl)
    ])
      .then(resultRes => {
        this.setOptionsMap('superviseUnit', resultRes[0]);
        resultRes[1].data = Array.isArray(resultRes[1].data) ? resultRes[1].data : [];
        resultRes[2].data = Array.isArray(resultRes[2].data) ? resultRes[2].data : [];
        this.orderTypeTreeOptions = this.keyDeepReplace(resultRes[1].data, {
          typeName: 'text',
          typeId: 'value',
          orderTypeDetailList: 'children',
          typeDetailName: 'text',
          typeDetailId: 'value'
        });
        this.fullTreeList(this.orderTypeTreeOptions, 1, {});
        this.orderTypeOptions = this.treeListFlat(this.orderTypeTreeOptions);
        this.orderTypeMap = Object.fromEntries(this.orderTypeOptions.map(item => [item.id, item.text]));
        this.areaCommunityAssignTreeOptions = this.keyDeepReplace(resultRes[2].data, {
          areaId: 'value',
          areaName: 'text',
          communityList: 'children',
          communityId: 'value',
          communityName: 'text',
          assignUnitList: 'children',
          assignUnitId: 'value',
          assignUnitName: 'text'
        });
        this.fullTreeList(this.areaCommunityAssignTreeOptions, 1, {});
        console.log('areaCommunityAssignTreeOptions => ', this.areaCommunityAssignTreeOptions);
        this.areaCommunityAssignOptions = this.treeListFlat(this.areaCommunityAssignTreeOptions);
        this.areaCommunityAssignMap = Object.fromEntries(this.areaCommunityAssignOptions.map(item => [item.id, item.text]));
        this.areaOptions = this.areaCommunityAssignTreeOptions.map(item => ({ ...item, children: void 0 }));
      })
      .finally(() => {
        this.getDetail();
      });
  },
  mounted() {
    const formRef = this.$refs.work_up_form;
    ['submit', 'validate', 'resetValidation', 'scrollToField'].forEach(key => {
      this[key] = formRef[key];
    });
    // const entries = Object.entries(this.$refs.work_up_form);
    // for (const [key, value] of entries) {
    //   this[key] = value;
    // }
  },
  beforeDestroy() {},
  methods: {
    fullTreeList(source, level = 1, parent) {
      source.forEach(item => {
        item.level = level;
        item.id = ('' + level).padStart(4, '0') + '-' + item.value;
        item.parentId = parent.id;
        item.fullIds = Array.isArray(parent.fullIds) ? [...parent.fullIds, item.id] : [item.id];
        if (Array.isArray(item.children) && item.children.length > 0) {
          this.fullTreeList(item.children, level + 1, item);
        } else {
          item.children = void 0;
        }
      });
    },
    keyDeepReplace(source, mappings) {
      if (Array.isArray(source)) {
        return source.map(item => this.keyDeepReplace(item, mappings));
      } else if (typeof source === 'object' && source !== null) {
        const result = {};
        Object.keys(source).forEach(key => {
          const newName = mappings[key] || key;
          if (typeof source[key] === 'object' && source[key] !== null) {
            result[newName] = this.keyDeepReplace(source[key], mappings);
          } else {
            result[newName] = source[key];
          }
        });
        return result;
      }
      return source;
    },
    treeListFlat(treeList) {
      const result = [];
      _.cloneDeep(treeList).forEach(item => {
        result.push({ ...item, children: void 0 });
        if (Array.isArray(item.children) && item.children.length > 0) {
          result.push(...this.treeListFlat(item.children));
        }
      });
      return result;
    },
    submitOnHandle(values) {
      this.$emit('submit', values, _.cloneDeep(this.form), _.cloneDeep(this.textForm));
    },
    getDetail() {
      if (!this.orderId) return;
      this.$axios
        .get(getOrderWorkDetailUrl, {
          params: { orderId: this.orderId }
        })
        .then(res => {
          if (res && res.code === 200) {
            res.data = res.data || {};
            const orderTypeFullIds = (this.orderTypeOptions.find(item => item.id === '0002-' + res.data.orderType) || {}).fullIds || [];
            this.textForm.orderTypeText = orderTypeFullIds
              .filter(item => !['', null, void 0, NaN].includes(item))
              .map(item => this.orderTypeMap[item])
              .join(' / ');
            this.textForm.areaText = this.areaCommunityAssignMap['0001-' + res.data.area];
            this.textForm.assignUnitText = ['0002-' + res.data.assignCommunity, '0003-' + res.data.assignUnit]
              .filter(item => !['', null, void 0, NaN].includes(item))
              .map(item => this.areaCommunityAssignMap[item])
              .join(' / ');
            this.textForm.superviseUnitText = this.superviseUnitMap[res.data.superviseUnit];
            Object.keys(this.form).forEach(k => {
              if (k === 'photos') {
                this.form[k] = res.data.imageUrl;
              } else {
                this.form[k] = res.data[k];
              }
            });
            this.existForm && this.existForm(res.data, this.form, this.textForm);
          }
        });
    },
    setOptionsMap(prop, res) {
      if (res && res.code === 200) {
        res.data = Array.isArray(res.data) ? res.data : [];
        this[prop + 'Options'] = res.data.map(item => ({
          text: item.dictName,
          value: item.dictCode
        }));
        this[prop + 'Map'] = Object.fromEntries(this[prop + 'Options'].map(item => [item.value, item.text]));
      }
    },
    mapPointOnClick() {
      if (this.mode !== 'edit') return;
      this.orderAddressShow = true;
    },
    mapSelectOnOk(values) {
      if (!values) return;
      this.form.orderAddress = values.fullAddress || '';
    },
    orderTypePickOnOk(value, index) {
      if (!Array.isArray(value) || !Array.isArray(index)) return;
      this.form.orderType = this.orderTypeTreeOptions[index[0]].children[index[1]].value;
      this.textForm.orderTypeText = value.filter(item => !!item).join(' / ');
      this.orderTypePickShow = false;
    },
    areaPickOnOk(value, index) {
      if (!value) return;
      const list = _.cloneDeep(this.areaCommunityAssignTreeOptions.find(item => item.id === value.id).children || []);
      list.forEach(item => {
        if (!Array.isArray(item.children) || (Array.isArray(item.children) && item.children.length === 0)) {
          item.children = [{ text: '', value: '' }];
        }
      });
      this.assignUnitTreeOptions = list;
      this.form.area = value.value;
      this.textForm.areaText = value.text;
      this.areaPickShow = false;
    },
    assignUnitPickOnOk(value, index) {
      if (!Array.isArray(value) || !Array.isArray(index)) return;
      this.form.assignCommunity = this.assignUnitTreeOptions[index[0]].value;
      this.form.assignUnit = this.assignUnitTreeOptions[index[0]].children[index[1]].value;
      this.textForm.assignUnitText = value.filter(item => !!item).join(' / ');
      this.assignUnitPickShow = false;
    },
    superviseUnitPickOnOk(value) {
      if (!value) return;
      this.form.superviseUnit = value.value;
      this.textForm.superviseUnitText = value.text;
      this.superviseUnitPickShow = false;
    }
  }
};
</script>

<style lang="less" scoped>
.work-up-form {
  box-sizing: border-box;
  border-radius: 16px;
  overflow: hidden;
  background-color: #ffffff;
}

.work-describe {
  flex-wrap: wrap;
  /deep/ .van-field__value {
    box-sizing: border-box;
    width: 100%;
    background-color: #f9f9f9;
    flex-shrink: 0;
    flex-basis: auto;
    padding: 8px 20px 8px 20px;
    border-radius: 16px;
  }
}
.work-photo {
  flex-wrap: wrap;
  /deep/ .van-field__value {
    box-sizing: border-box;
    width: 100%;
    flex-shrink: 0;
    flex-basis: auto;
  }
}

/deep/ .van-field__right-icon {
  line-height: normal;
}
</style>
