// 查询 - 字典表
const getDictOpsUrl = `/cyproxy/dict/getDictListByType`;

// 新增 - 事件
const addOrderWorkUrl = `/cyproxy/order-info/addInfo`;

// 查询 - 事件 - 详情
const getOrderWorkDetailUrl = `/cyproxy/order-info/getOrderInfoById`;

// 查询 - 事件类型 - 详情
const getSjlxListUrl = `/cyproxy/dict/getOrderTypeAllList`;

// 查询 - 片区-社区-单位 - 详情
const getPqSqDwListUrl = `/cyproxy/dict/getAreaCommunityUnitAllList`;

export { getDictOpsUrl, addOrderWorkUrl, getOrderWorkDetailUrl, getSjlxListUrl, getPqSqDwListUrl };
