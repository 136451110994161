<template>
  <van-popup
    v-model="popupShow"
    position="right"
    :close-on-popstate="true"
    :style="{ width: '100%', height: '100%', overflow: 'hidden' }"
    @close="$emit('update:popupShow', false)"
  >
    <div class="by-map-get-info">
      <div class="a-map" id="a_map"></div>
      <div class="header-search">
        <div class="search-content">
          <input
            class="search-input"
            id="search_input"
            v-model="searchText"
            type="search"
            placeholder="请输入关键字"
            @search="searchOnHandle"
          />
          <div class="search-btn" @click="searchOnHandle">搜索</div>
        </div>
      </div>

      <div class="search-result-content" :class="{ hidden: !searchResultShow }">
        <van-icon name="cross" @click.stop="searchResultShow = false" />
        <div class="search-result-item" v-for="(point, index) in pointList" :key="'ssjgitem' + index" @click="ssjgOnClick(point, index)">
          <div class="name-text">{{ point.name }}</div>
          <div class="address-text">地址：{{ point.fullAddress }}</div>
        </div>
        <div v-show="pointList.length === 0" class="empty-content">暂无数据</div>
      </div>
      <div class="select-result-content" :class="{ hidden: !selectResultShow }">
        <van-icon name="cross" @click.stop="selectResultShow = false" />
        <div class="select-result-item active">
          <div class="name-text">{{ detail.name }}</div>
          <div class="address-text">地址：{{ detail.fullAddress }}</div>
        </div>
        <div class="btns">
          <div class="btn cancel-btn" @click="$emit('update:popupShow', false)">取消</div>
          <div class="btn apply-btn" @click="applyOnClick">确定</div>
        </div>
      </div>
    </div>
  </van-popup>
</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader';
import pointIcon from './img/point.png';
import pointSelectIcon from './img/point_select.png';
import _ from 'lodash';
import { Icon } from 'vant';

window._AMapSecurityConfig = { securityJsCode: '14e8f6657d33d80943c81fd846b0735b' };

export default {
  name: 'byMapGetInfo',
  components: {
    [Icon.name]: Icon
  },
  props: {
    popupShow: {
      type: Boolean,
      default: false
    }
  },
  model: {},
  data() {
    return {
      searchText: '',
      oldSearchText: '',
      defaultLongitude: '120.18211674081908',
      defaultLatitude: '30.243799716007448',
      longitude: '',
      latitude: '',
      lnglat: [],
      detail: {
        longitude: '', // 经度
        latitude: '', // 纬度
        name: '', // 名称
        address: '', // 地址
        fullAddress: ''
      },
      pointList: [],
      pointClickList: [],
      markerList: [],
      searchResultShow: false,
      selectResultShow: false,
      AMap: null,
      pointIcon: null,
      pointSelectIcon: null,
      map: null,
      autoComplete: null,
      placeSearch: null,
      geocoder: null,
      infoWindow: null,
      marker: null
    };
  },
  computed: {},
  watch: {
    popupShow: {
      handler(value) {
        if (value === true && !this.map) {
          this.initAMap();
        }
      },
      immediate: true
    }
  },
  created() {
    this.longitude = localStorage.getItem('longitude') ?? this.defaultLongitude;
    this.latitude = localStorage.getItem('latitude') ?? this.defaultLatitude;
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    initAMap() {
      AMapLoader.load({
        key: '6ccc57a1965684bcf1d56bf846608b44',
        version: '2.0',
        plugins: [
          'AMap.Scale',
          'AMap.ToolBar',
          'AMap.ControlBar',
          'AMap.Geocoder',
          'AMap.Marker',
          'AMap.CitySearch',
          'AMap.Geolocation',
          'AMap.InfoWindow',
          'AMap.AutoComplete',
          'AMap.PlaceSearch'
        ]
      })
        .then(AMap => {
          this.map = new AMap.Map('a_map', {
            viewMode: '3D',
            zoom: 16,
            center: [this.longitude, this.latitude],
            resizeEnable: true,
            zoomEnable: true,
            dragEnable: true,
            doubleClickZoom: false
          });
          this.AMap = AMap;
          this.pointIcon = new AMap.Icon({ image: pointIcon, size: new this.AMap.Size(26, 26), imageSize: new this.AMap.Size(26, 26) });
          this.pointSelectIcon = new AMap.Icon({
            image: pointSelectIcon,
            size: new this.AMap.Size(36, 36),
            imageSize: new this.AMap.Size(36, 36)
          });
          this.autoComplete = new AMap.AutoComplete({});
          this.placeSearch = new AMap.PlaceSearch({ extensions: 'all', pageSize: 20 });
          this.geocoder = new AMap.Geocoder({});
          this.infoWindow = new AMap.InfoWindow({
            // position: [point.location.KL, point.location.kT],
            offset: [0, -36],
            content: '',
            // size: new AMap.Size(200, 200),
            autoMove: false,
            closeWhenClickMap: false,
            anchor: 'bottom-center'
          });

          this.map.on('click', event => {
            // this.getAdress([event.lnglat.KL, event.lnglat.kT]);
          });
        })
        .catch(error => {
          console.error('load-error => ', error);
        });
    },
    getAdress(lnglat) {
      this.geocoder.getAddress(lnglat, (status, result) => {
        if (status === 'complete' && result.regeocode) {
          const { province, city, district, township, street, streetNumber } = result.regeocode.fullAddress || {};
          this.detail.longitude = lnglat[0];
          this.detail.latitude = lnglat[1];
          this.detail.name = result.regeocode.formattedAddress;
          this.detail.address = result.regeocode.formattedAddress;
          this.detail.fullAddress = `${province || ''}${city || ''}${district || ''}${township || ''}${street || ''}${streetNumber || ''}`;
        }
      });
    },
    applyOnClick() {
      this.$emit('update:popupShow', false);
      this.$emit('ok', this.detail);
    },
    searchOnHandle() {
      this.selectResultShow = false;
      this.searchResultShow = true;
      if (!this.searchText || this.oldSearchText === this.searchText) return;
      this.oldSearchText = this.searchText;
      this.placeSearch.search(this.searchText, (status, result) => {
        if (status === 'complete') {
          result.poiList = result.poiList || {};
          result.poiList.pois = Array.isArray(result.poiList.pois) ? result.poiList.pois : [];
          this.pointList.forEach(point => {
            if (point.marker) {
              point.marker.remove();
            }
          });
          result.poiList.pois.forEach(point => {
            point.fullAddress = `${point.pname || ''}${point.cityname || ''}${point.adname || ''}${
              point.address === point.adname ? '' : point.address || ''
            }`;
            point.marker = new this.AMap.Marker({
              position: [point.location.KL, point.location.kT],
              title: point.name,
              icon: this.pointIcon,
              anchor: 'bottom-center',
              // label: { content: point.name, direction: 'bottom' },
              topWhenClick: true,
              extData: { id: point.id, name: point.name, address: point.address },
              map: this.map
            });
            point.marker.on('click', event => {
              this.pointMarkerOnClick(event, event?.target?._opts.extData);
            });
          });
          this.pointList = result.poiList.pois;
          this.map.setCenter([this.pointList[0].location.KL, this.pointList[0].location.kT]);
        }
      });
    },
    pointMarkerOnClick: _.debounce(function (event, extData) {
      if (extData && extData?.id) {
        this.pointList.forEach(point => {
          if (extData.id === point.id) {
            point.marker.setIcon(this.pointSelectIcon);
            const content = `
            <div style="white-space:nowrap;font-size:18px;font-weight:700;color:rgba(0,0,0,0.85);width:200px;overflow:hidden;text-overflow:ellipsis;">${point.name}</div>
            <div style="font-size:14px;color:rgba(0,0,0,0.5);width:200px">地址：${point.fullAddress}</div>
            `;
            this.infoWindow.setContent(content);
            this.infoWindow.open(this.map, [point.location.KL, point.location.kT]);
            this.detail.longitude = point.location.KL;
            this.detail.latitude = point.location.kT;
            this.detail.name = point.name;
            this.detail.address = point.address;
            this.detail.fullAddress = point.fullAddress;
            this.selectResultShow = true;
            this.searchResultShow = false;
          } else {
            point.marker.setIcon(this.pointIcon);
          }
        });
      }
    }, 100),
    ssjgOnClick(selPoint, index) {
      this.searchResultShow = false;
      this.selectResultShow = true;
      this.map.setCenter([selPoint.location.KL, selPoint.location.kT]);
      this.pointList.forEach(point => {
        if (selPoint.id === point.id) {
          point.marker.setIcon(this.pointSelectIcon);
          const content = `
            <div style="white-space:nowrap;font-size:18px;font-weight:700;color:rgba(0,0,0,0.85);width:200px;overflow:hidden;text-overflow:ellipsis;">${point.name}</div>
            <div style="font-size:14px;color:rgba(0,0,0,0.5);width:200px">地址：${point.fullAddress}</div>
            `;
          this.infoWindow.setContent(content);
          this.infoWindow.open(this.map, [point.location.KL, point.location.kT]);
          this.detail.longitude = point.location.KL;
          this.detail.latitude = point.location.kT;
          this.detail.name = point.name;
          this.detail.address = point.address;
          this.detail.fullAddress = point.fullAddress;
          this.selectResultShow = true;
          this.searchResultShow = false;
        } else {
          point.marker.setIcon(this.pointIcon);
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.by-map-get-info {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding-top: 100px;
  overflow: hidden;
}
.a-map {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}
.header-search {
  box-sizing: border-box;
  width: 100vw;
  height: 100px;
  position: fixed;
  left: 0px;
  top: 0px;
  background-color: #ffffff;
  padding: 16px 30px;
  .search-content {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border: 2px solid #3d7bfa;
    background-color: #f2f6ff;
    border-radius: 38px;
    overflow: hidden;
    display: flex;
    .search-input {
      box-sizing: border-box;
      flex-grow: 1;
      height: 100%;
      font-size: 28px;
      color: rgba(0, 0, 0, 0.85);
      padding: 0px 20px 0px 48px;
      background-color: transparent;
    }
    .search-btn {
      box-sizing: border-box;
      flex-shrink: 0;
      width: 150px;
      height: 100%;
      background-color: #3d7bfa;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: inherit;
      font-size: 28px;
    }
  }
}
.search-result-content {
  box-sizing: border-box;
  width: 100%;
  height: 40vh;
  background-color: #ffffff;
  border-radius: 16px 16px 0px 0px;
  position: fixed;
  left: 0px;
  bottom: 0px;
  transition: bottom 0.3s ease 0.3s;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 10px 20px 30px;
  .van-icon-cross {
    position: absolute;
    right: 20px;
    top: 10px;
    color: rgba(0, 0, 0, 0.5);
  }
  .search-result-item {
    box-sizing: border-box;
    padding: 10px 20px;
    border-bottom: 1px solid #eeeeee;
    .name-text {
      font-size: 28px;
      font-weight: 700;
      color: rgba(0, 0, 0, 0.85);
    }
    .address-text {
      font-size: 20px;
      color: rgba(0, 0, 0, 0.5);
      margin-top: 5px;
    }
  }
  .empty-content {
    box-sizing: border-box;
    font-size: 48px;
    text-align: center;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(0, 0, 0, 0.3);
  }
  &.hidden {
    bottom: -40vh;
    transition: bottom 0.3s ease 0s;
  }
}
.select-result-content {
  box-sizing: border-box;
  width: 100%;
  height: 260px;
  background-color: #ffffff;
  border-radius: 16px 16px 0px 0px;
  position: fixed;
  left: 0px;
  bottom: 0px;
  transition: bottom 0.3s ease 0.3s;
  .van-icon-cross {
    position: absolute;
    right: 20px;
    top: 10px;
    color: rgba(0, 0, 0, 0.5);
  }
  .select-result-item {
    box-sizing: border-box;
    border-radius: 16px 16px 0px 0px;
    border: 1px solid #3d7bfa;
    background-color: rgba(61, 123, 250, 0.1);
    padding: 16px 20px;
    .name-text {
      font-size: 28px;
      font-weight: 700;
      color: rgba(0, 0, 0, 0.85);
    }
    .address-text {
      font-size: 20px;
      color: rgba(0, 0, 0, 0.5);
      margin-top: 5px;
    }
  }
  .btns {
    box-sizing: border-box;
    padding: 0px 20px;
    display: flex;
    justify-content: space-around;
    height: 80px;
    margin-top: 28px;
    .btn {
      box-sizing: border-box;
      width: 30%;
      height: 100%;
      border-radius: 40px;
      font-size: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      &.cancel-btn {
        color: #5d5d5d;
        background: linear-gradient(180deg, #ededed 0%, #d8d8d8 100%);
      }
      &.apply-btn {
        color: #ffffff;
        background: linear-gradient(135deg, #65a0f9 0%, #3067ff 100%);
      }
    }
  }
  &.hidden {
    bottom: -260px;
    transition: bottom 0.3s ease 0s;
  }
}
/deep/ .amap-logo,
/deep/ .amap-copyright {
  display: none !important;
}

/deep/ input[type='search']::-webkit-search-decoration,
/deep/ input[type='search']::-webkit-search-cancel-button {
  -webkit-appearance: none;
  display: none;
}
/deep/ input[type='search'] {
  appearance: textfield;
  -moz-appearance: textfield;
}
/deep/ input[type='search']::-ms-clear,
/deep/ input[type='search']::-ms-reveal {
  display: none;
  pointer-events: none;
  width: 0;
  height: 0;
}
</style>

<style lang="less">
.amap-sug-result {
  z-index: 3000;
}
</style>
